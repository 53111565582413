<template>
  <div class="kuang_ping_nwes">
    <div class="news_xianq_k px1200">
      <div class="dqwz_S"> 当前位置：<my-link href="/home-layout">首页</my-link> > <my-link href="/news">新闻资讯</my-link> > 详情</div>
      <div class="neor">
        <div class="biaot">
          <h3>{{news.title}}</h3>
          <div class="xia_sd">
            <span>{{ news.publishTime|dateToStr }}</span>
<!--            <span>422</span>-->
<!--            <span>信息来源：先行者</span>-->
            <div class="bshare-custom" style="display: inline-block;">
              <div class="bsPromo bsPromo2"></div>
            </div>
          </div>
        </div>
        <div class="text" v-html="news.content">
        </div>
<!--        <div class="fanye">-->
<!--          <p> 上一篇： <a href="#">帘上的空</a></p>-->
<!--          <p> 下一篇： <a href="#">岁月的倒影也将消失</a></p>-->
<!--        </div>-->
      </div>
    </div>
  </div>
<!--  <div class="wrap-lg">-->
<!--    <el-breadcrumb separator-class="el-icon-arrow-right" style="padding: 20px 0">-->
<!--      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>-->
<!--      <el-breadcrumb-item :to="{ path: '/news' }">新闻资讯</el-breadcrumb-item>-->
<!--      <el-breadcrumb-item>{{news.title}}</el-breadcrumb-item>-->
<!--    </el-breadcrumb>-->
<!--    <el-card style="background: #ccc0; border: 1px solid #ffffff50;">-->
<!--      <el-row :gutter="20">-->
<!--        <el-col :span="24"  class="reach-html" >-->
<!--         <div v-html="news.content"></div>-->
<!--        </el-col>-->
<!--      </el-row>-->
<!--    </el-card>-->
<!--  </div>-->
</template>

<script>
import { getInfo as industry } from '@/api/news/industry'
export default {
  name: 'detail',
  data () {
    return {
      sid: '',
      st: '',
      news: {
        content: ''
      }
    }
  },
  mounted () {
    this.sid = this.$route.query.sid
    this.st = this.$route.query.st
    this.getDetail()
  },
  methods: {
    toPage (path) {
      this.$router.push({
        name: path
      })
    },
    getDetail () {
      let param = { id: this.sid }
      if (this.st === '2') {
        this.getIndustry(param)
      }
    },
    getIndustry (param) {
      industry(param).then(res => {
        this.loading = false
        var result = res.data
        if (result.rtnCode !== '0') {
          this.$message({
            type: 'info',
            message: result.msg
          })
        } else {
          this.news = result.bizData
        }
      }).catch(ex => {
        this.loading = false
        this.$message.error('列表加载失败，请稍后再试！')
      })
    }
  }
}
</script>

<style scoped>
/deep/.el-breadcrumb__inner.is-link, .el-breadcrumb__inner a {
  color: #4f080e;
}
  h4{
    border-left: solid 6px #32A08C;
    padding-left: 10px;
  }
</style>
